import React from 'react'
import "./style.css"

function PortalNav() {
  return (
    <div className="portal-nav-container">
        <div className="portal-nav-text-container">
            <a >Applicants</a>
            <a href="https://recruiter.internguys.com/">Recruiters</a>
        </div>
    </div>
  )
}

export default PortalNav