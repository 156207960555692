import React, { useState } from "react";
import Images from "../../Images";
import Spinner from "../Spinner/spinner";
import BottomText from "../BottomText";
import "./style.css";
import $ from "jquery";
import ExternalAuth from "../ExternalAuth";
import { storeToken } from "../../authetication/loginState";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useAnalyticsEventTracker from "../../helper/reactTracker";
import { Typography } from "@material-ui/core";
import ForgotPassModal from "./forgotPassModal";
import login_img from "../../images/login.svg"

const loginEndpoint = process.env.REACT_APP_LOGIN_ENDPOINT;
const LoginForm = ({ loginState }, props) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isSignup, setIsSignup] = useState(true);
  const [password, setPassword] = useState("");
  const [needNewEmail, setNeedNewEmail] = useState("");
  const [isWrongCredentials, setIsWrongCredentials] = useState("false");
  const [hidden, setHidden] = useState("false");
  let navigate = useNavigate();
  const location = useLocation();
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loggingIn, setLoggingIn] = useState("");
  const [signingUp, setSigningUp] = useState("");
  const [userType, setUserType] = useState("student");
  const gaEventTracker = useAnalyticsEventTracker("Register");

  const [showPassModal, setShowPassModal] = useState(false);

  //end points
  const registerEndpoint = process.env.REACT_APP_REGISTER_ENDPOINT;
  const loginEndpoint = process.env.REACT_APP_LOGIN_ENDPOINT;

  const redirectRecruiter = (event) => {
    setUserType(event);
    if (event == "recruiter") {
      window.location.href = "https://recruiter.internguys.com/";
    }
  };

  const validateError = () => {
    setNameError("");
    setEmailError("");
    setPasswordError("");
    let reNameError = "";
    let reEmailError = "";
    let rePasswordError = "";
    setNeedNewEmail("");
    var regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (name === "") {
      reNameError = "Name cannot be empty";
      setNameError(reNameError);
    }

    if (email === "") {
      reEmailError = "Email cannot be Empty";
      setEmailError(reEmailError);
    }
    if (password === "") {
      rePasswordError = "Password cannot be empty";
      setPasswordError(rePasswordError);
    }

    if (!email.includes("@") && email !== "") {
      reEmailError = "Invalid Email";
      setEmailError(reEmailError);
    }

    if (!password.match(regex) && password !== "") {
      rePasswordError =
        "Must have password with min 8 characters containing atleast one uppercase letter, one lowercase, one number and one special character";
      setPasswordError(rePasswordError);
    }

    if (rePasswordError === "" && reEmailError === "" && reNameError === "") {
      return false;
    }

    return true;
  };

  const login = (e) => {
    e.preventDefault();
    $.ajaxSetup({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    setLoggingIn("user logging in");

    let m = $.post(
      `${loginEndpoint}`,
      JSON.stringify({ Email: email, Password: password }),
      (data) => {
        localStorage.setItem("userEmail", email);
        setIsWrongCredentials("false");
        storeToken(data);
        loginState.setLoggedIn(true);
        navigate("/mockInterviewerInput");
      }
    ).fail((data2) => {
      if (data2.status === 401) {
        setIsWrongCredentials("true");
        setLoggingIn("");
        document.getElementById("wrongCredentials").style.display = "block";
        document.getElementById("userSessionExpired").style.display = "none";
      }
    });
  };

  const passShowHide = (e) => {
    e.preventDefault();
    if (hidden === "false") {
      setHidden("true");
    } else {
      setHidden("false");
    }
  };

  const userSessionPrompt = () => {
    if (location.state === "userSessionExpired") {
      location.state = "done";
    }
  };

  const register = (e) => {
    // Insert Loader here
    e.preventDefault();

    setSigningUp("user signing up");

    let registerNotOk = validateError();

    if (registerNotOk) {
      document.getElementById("invalidSignUp").style.display = "block";
      document.getElementById("userSessionExpired").style.display = "none";
      setTimeout(() => setSigningUp(""), 500);
    } else {
      document.getElementById("invalidSignUp").style.display = "none";
      setSigningUp("user signing up");

      $.ajaxSetup({
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      $.post(
        `${registerEndpoint}`,
        JSON.stringify({ Name: name, Email: email, Password: password }),
        (res) => {
          $.post(
            `${loginEndpoint}`,
            JSON.stringify({ Email: email, Password: password }),
            (data) => {
              gaEventTracker("register");
              localStorage.setItem("userEmail", email);
              storeToken(data);
              loginState.setLoggedIn(true);
              navigate("/mockInterviewerInput");
            }
          ).fail((data2) => {
            setSigningUp("");
          });
        }
      ).fail((data3) => {
        if (!registerNotOk) {
          setNeedNewEmail("Email already exists");
        }
        document.getElementById("invalidSignUp").style.display = "block";
        document.getElementById("userSessionExpired").style.display = "none";
        setSigningUp("");
      });
    }
  };

  const trueForgotModal = (event) => {
    event.preventDefault();
    setShowPassModal(true);
  };

  const falseForgotModal = () => {
    setShowPassModal(false);
  };

  return (
    <>
      <div>{loggingIn === "user logging in" ? <Spinner /> : <p></p>}</div>
      <div>{signingUp === "user signing up" ? <Spinner /> : <p></p>}</div>
      <div
        className="bg-img-login"
        style={{
          backgroundImage: `url(${Images.BACKGROUND})`,
          backgroundRepeat: "repeat-y",
          backgroundSize: "cover",
          minWidth: "100vw",
          minHeight: "100vh",
        }}
      >
        <img
          className="ig-logo"
          src={Images.IG_LOGO2}
          alt="Intern guys"
          onClick={() => navigate("/")}
        />

        {showPassModal && <ForgotPassModal clickOutside = {falseForgotModal}/>}

        <form onSubmit={isSignup ? register : login}>
          <div className="grid-wrapper">
            <div className="login-details">
              <h1 className="title">Welcome to the Future of Interview Preparation</h1>

              <div id="wrongCredentials">
                <h3>Invalid Credentials</h3>
                <h4>Either the email or the password is incorrect</h4>
              </div>

              <div id="invalidSignUp">
                <h3>Invalid Input</h3>
                <h4>{nameError}</h4>
                <h4>{emailError}</h4>
                <h4>{needNewEmail}</h4>
                <h4>{passwordError}</h4>
              </div>

              {location.state === "userSessionExpired" ? (
                userSessionPrompt()
              ) : (
                <p></p>
              )}

              <div
                id="userSessionExpired"
                style={
                  location.state === "done"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <h3>User Session Expired</h3>
                <h4>Please log in again</h4>
              </div>

              <div className="text-fields-container">
                {isSignup === true ? (
                  <div class="user-input-holder">
                    <label for="user-type" class="user-input-label">
                      I'm a:
                    </label>
                    <select
                      id="user-type"
                      name="user-type"
                      className="user-input"
                      value={userType}
                      onChange={(e) => redirectRecruiter(e.target.value)}
                    >
                      <option value="student">Student</option>
                      <option value="recruiter">Recruiter</option>
                    </select>
                  </div>
                ) : null}

                {isSignup === true ? (
                  <div className="text-input-signup">
                    <input
                      variant="outlined"
                      placeholder="Name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      name="name"
                      className="text-input"
                    />
                  </div>
                ) : null}

                <div className="text-input-signup">
                  <input
                    id="outlined-basic"
                    type="email"
                    variant="outlined"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="text-input"
                    name="email"
                  />
                </div>
                <div className="text-input-signup">
                  <input
                    id="outlined-basic"
                    variant="outlined"
                    label="Password"
                    placeholder="Password"
                    className="text-input"
                    type={hidden === "false" ? "password" : "text"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                  />
                  <img
                    className={!isSignup ? "lg-eyeicon" : "eyeicon"}
                    src={
                      hidden === "false" ? Images.EYE_CLOSED : Images.EYE_OPEN
                    }
                    alt="Instagram"
                    onClick={(e) => passShowHide(e)}
                  />
                </div>
              </div>
              <p className="password_forgot">
                <button
                  className="login__forgot-link"
                  onClick={trueForgotModal}
                >
                  Forgot Password?
                </button>
              </p>

              <button type="submit" className="login-loginbtn">
                {isSignup ? "Sign up" : "Login"}
              </button>

              {isWrongCredentials === "true" && (
                <p className="wrongInfo">
                </p>
              )}
              <BottomText>
                {isSignup ? (
                  <div className="btm-text">
                    <Typography variant="h5" className="mx-2">
                      Already have an account!
                    </Typography>
                    <span
                      onClick={() => {
                        setIsSignup(false);
                        document.getElementById(
                          "wrongCredentials"
                        ).style.display = "none";
                        document.getElementById("invalidSignUp").style.display =
                          "none";
                        document.getElementById(
                          "userSessionExpired"
                        ).style.display = "none";
                      }}
                      style={{
                        fontWeight: "bold",
                        cursor: "pointer",
                        color: "black",
                        textDecoration: "none",
                        paddingBottom:"2px"
                      }}
                    >
                      Login
                    </span>
                  </div>
                ) : (
                  <div className="btm-text">
                    <Typography variant="h5" className="mx-2">
                      New Member?
                    </Typography>
                    <span
                      onClick={() => {
                        setIsSignup(true);
                        document.getElementById(
                          "wrongCredentials"
                        ).style.display = "none";
                        document.getElementById("invalidSignUp").style.display =
                          "none";
                      }}
                      style={{
                        fontWeight: "bold",
                        cursor: "pointer",
                        color: "black",
                        textDecoration: "none",
                      
                      }}
                    >
                      Sign up
                    </span>
                  </div>
                )}
              </BottomText>
              <h2 className="loginwith">Login With</h2>
              <ExternalAuth  isSignup={isSignup}/>
            </div>
            <div className="login-pic-wrap">
              <img className="login-pic" src={login_img} />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginForm;
