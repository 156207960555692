import React, { useState } from 'react'
import "./style.css"
import Timeline from "@mui/lab/Timeline";
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import LeftArrow from '../../images/homepage/arrow-left.svg';
import RightArrow from '../../images/homepage/arrow-right.svg';
import Carousel from 'react-multi-carousel';
import Footer from '../../components/Footer';
import Stars from '../../images/homepage/stars-five.svg';
import Hannan  from "../../images/about/Hannan.jpg";
import Kimberly from "../../images/about/Kimberly.jpg"
import Brandon from "../../images/about/Brandon.jpg"
import Clarence from "../../images/about/Clarence.jpg"
import Nitya from "../../images/about/Nitya.jpg"
import Osbert from "../../images/about/Osbert.jpg"
import Connor from "../../images/about/Connor.jpg"

function AboutUs() {

    const [timelinedot_status,setTimelinedot_status]=useState([0,0,1]);
    const timeline_content_text=[
        "Intern Guys was born out of our own struggles to land internships as students. We spent laborious months applying and interviewing for internships in many companies, until we realized that the current career platforms weren't making the process any easier. We felt there was a need to re-imagine the current internship solution, one that is less painful, less stressful, less time-consuming while being efficient.",
        "Slowly but surely we started to look for solutions to our problem we as students faced commonly and for this we interviewed hundreds of students to understand their struggles. What was needed was a platform that reinvents the internship cycle, how students make resumes, reinvent how students find internships, and reinvent how student apply. There was a clear need to make an internship platform that is tailored for students : fast, fun, millennial-friendly and did we mention fast?!",
        "Running this startup has been a wild, exciting ride. But as we’ve grown, we’ve evolved too. Nora, the AI Mock Interviewer is the next step in our journey. While we started by focusing on internships, we now help candidates sharpen their interview skills and feel confident going into interviews. There's nothing quite like hearing how Nora has helped someone land their dream role—that's our biggest reward!"
    ]
    const reviews_data=[
        {
            content: "Intern Guys makes it simple to apply to internships with everything you need in one website. Its just create a resume, search for internships, and apply!",
            person: "Crystal Lai-Ton-Nu",
            position: "Informatics Major, Senior",
        },
        {
            content: " I really like the search feature Intern Guys provides. Their match making algorithm provides me with positions best catered to me with percentages! Lets say you have 90% match for a position, they even let you know the other 10% where you can improve!",
            person: "Tori Song",
            position: "Marketing Major, Sophomore",
        },
        {
            content: "Great website - love their UI! They have a fun, light, Netflix vibe to their website and I really vibe to it. Getting an internship doesn’t have to be so serious, rather can be fun, and Intern Guys plays that card really well!",
            person: "Ashwin Atthiappan",
            position: "Data Science Major, Freshman",
        },
        {
            content: 'Great resource for students trying to get into the professional world. The whole process of creating a resume, finding open positions, and applying was exactly what I needed to be done.',
            person: 'Daniella Park.',
            position: 'Computer Engineer Major, Sophomore',
        },
        {
            content: 'Easiest and honestly the most fun platform out there to get an internship! Since its a student started platform Intern Guys knows exactly what can help us land an internship. Must try!',
            person: 'Benjamin S.',
            position: 'Business Economics Major, Junior',
        },
    ]
    const team_data=[
        {
            profile_image:Hannan,
            name:"Hannan Hussain",
            role:"CEO",
            linkedIn:"https://www.linkedin.com/in/hannan-hussain-71332b148/",
            alt_text:"A photo of Hannan Hussain standing in front of a neutral textured background in a dark blue suit."
        },
        {
            profile_image:Nitya,
            name:"Nitya Khanna",
            role:"UI/UX",
            linkedIn:"https://www.linkedin.com/in/nityakhanna77/",
            alt_text:"A photo of Nitya Khanna standing in a bright outdoor hallway in a black suit."
        },
        {
            profile_image:Osbert,
            name:"Osbert Reynaldi Sudjana",
            role:"UI/UX",
            linkedIn:"https://www.linkedin.com/in/osbert-reynaldi-sudjana/",
            alt_text:"A photo of Osbert Reynaldi Sudjana standing in a bright outdoor hallway under an archway. He is wearing a black shirt."
        },
        {
            profile_image:Brandon,
            name:"Brandon Le",
            role:"Software Engineer",
            linkedIn:"https://www.linkedin.com/in/brandon-tran-le",
            alt_text:"A photo of Brandon Le standing outside with a brick academic building in the background, wearing an open dark suit."
        },
        {
            profile_image:Kimberly,
            name:"Kimberly Nguyen",
            role:"Software Engineer",
            linkedIn:"https://www.linkedin.com/in/kimberlynguyenh/",
            alt_text:"A photo of Kimberly Nguyen standing in a bright outdoor hallway with an archway in the background, wearing a dark blazer."
        },
        {
            profile_image:Clarence,
            name:"Clarence Lin",
            role:"Software Engineer",
            linkedIn:"https://www.linkedin.com/in/clarencelin-",
            alt_text:"A photo of Clarence Lin standing outside with a snowy, sheer rock face in the background. He is wearing a blue jacket."
        },
        {
            profile_image:Connor,
            name:"Connor Flick",
            role:"Software Engineer",
            linkedIn:"https://www.linkedin.com/in/hiimconnor",
            alt_text:"A photo of Connor Flick standing in front of a neutral industrial background, wearing a bright yellow flannel."
        },

    ]
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 670 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 670, min: 0 },
          items: 1
        }
      };
    function CustomLeftArrow({ onClick }) {
        return (
            <img
            src={LeftArrow}
            onClick={onClick}
            className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
            />
        );
    }
    function CustomRightArrow({ onClick }) {
        return (
            <img
            src={RightArrow}
            onClick={onClick}
            className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
            />
        );
    }
    const open_link=(url)=>{
        window.open(url,'_blank');
    }
    return (
        <div className="about_us_page">
            <div className="history_container">
                <div className="history_banner">
                    <div className="history_h1_container">
                        <h1>
                            We're transforming how candidates prepare for interviews
                        </h1>
                    </div>   
                </div>
                <div className="timeline_title_container">
                    <h1>Our History</h1>
                </div>
                <div className="history_timeline_container">

                    <div className="history_timeline">
                        <Timeline
                            position="left"
                            sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                                },
                            }}
                        >
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot className={`timeline_dot ${timelinedot_status[0]?'active_dot':''}`} variant={timelinedot_status[0]?'filled':'outline'} onClick={(e)=>{setTimelinedot_status([1,0,0])}}/>
                                    <TimelineConnector className="timeline_connector"/>
                                </TimelineSeparator>
                                <TimelineContent className={`timeline_content ${timelinedot_status[0]?'active_content':''}`} onClick={(e)=>{setTimelinedot_status([1,0,0])}}>2022</TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot className={`timeline_dot ${timelinedot_status[1]?'active_dot':''}`} variant={timelinedot_status[1]?'filled':'outline'} onClick={(e)=>{setTimelinedot_status([0,1,0])}}/>
                                    <TimelineConnector className="timeline_connector"/>
                                </TimelineSeparator>
                                <TimelineContent className={`timeline_content ${timelinedot_status[1]?'active_content':''}`} onClick={(e)=>{setTimelinedot_status([0,1,0])}}>2023</TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot className={`timeline_dot ${timelinedot_status[2]?'active_dot':''}`} variant={timelinedot_status[2]?'filled':'outline'}  onClick={(e)=>{setTimelinedot_status([0,0,1])}}/>
                                </TimelineSeparator>
                                <TimelineContent className={`timeline_content ${timelinedot_status[2]?'active_content':''}`} onClick={(e)=>{setTimelinedot_status([0,0,1])}}>Now</TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    </div> 
                    <div className="history_timeline_text">
                            <p >{timeline_content_text[timelinedot_status.indexOf(1)]}</p>
                    </div>
                </div>
                   
            </div>
            <div className="review_container">
                <div className="title_container">
                    <h1>Hear from our users</h1>
                </div>
                <div className="carousel_wrapper_about">
                    <Carousel 
                        className='carousel' 
                        responsive={responsive}
                        showDots={true}
                        arrows={true}
                        swipeable={true}
                        customLeftArrow={<CustomLeftArrow />}
                        customRightArrow={<CustomRightArrow />}
                    >
                        {reviews_data.map((review)=>{
                            return(
                                <div className="item">
                                    <p className="reviews">
                                        {review.content}
                                    </p>
                                    <div className="review_bottom_container">
                                        <img className="review_stars" src={Stars}/>
                                        <p className="reviews_student_name">
                                            {review.person}
                                        </p>
                                        <p className="reviews_student_major">
                                            {review.position}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                        
                    </Carousel>
                </div>
            </div>
            <div className="team_container">
                <div className="team_header_container">
                    <h1>Meet the team revolutionizing interview prep for everyone</h1>
                </div>
                <div className="team_grid">
                    {team_data.map((member)=>{
                        return(
                            <div className="grid_item_container">
                                <img src={member.profile_image} alt={member.alt_text} onClick={()=>{open_link(member.linkedIn)}}/>
                                <p className="grid_name">{member.name}</p>
                                <p className="grid_role">{member.role}</p>
                            </div>
                        )
                    })}
                </div> 
            </div>
            <div className="signup_container">
                <h1>Sign Up Now!</h1>
                <div className="button_container">
                    <a href="/login">
                        <div className="button">
                            <p>I'm interviewing</p>
                        </div>
                    </a>
                    <a href="/login">
                        <div className="button">
                            <p>I'm a Recruiter</p>
                        </div>
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AboutUs