import React, {useState, useEffect, useRef} from 'react';
import './styles.css';
import 'react-multi-carousel/lib/styles.css';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';

import SHADES from '../../images/homepage/shadesNews.png';
import TESLA from '../../images/homepage/tesla.png';
import AIRBNB from '../../images/homepage/airbnb.png';
import AMAZON from '../../images/homepage/amazon.png';
import APPLE from '../../images/homepage/apple.png';
import CHASE from '../../images/homepage/chase.png';
import CNBC from '../../images/homepage/cnbc.png';
import FIGMA from '../../images/homepage/figma.png';
import GOLDMANSACHS from '../../images/homepage/goldmansachs.png';
import GOOGLE from '../../images/homepage/google.png';
import JNJ from '../../images/homepage/jnj.png';
import KPMG from '../../images/homepage/kpmg.png';
import LOCKHEEDMARTIN from '../../images/homepage/lockheedmartin.png';
import META from '../../images/homepage/meta.png';
import NVIDIA from '../../images/homepage/nvidia.png';
import ROBINHOOD from '../../images/homepage/robinhood.png';
import STRIPE from '../../images/homepage/stripe.png';
import TIKTOK from '../../images/homepage/tiktok.png';
import WALMART from '../../images/homepage/walmart.png';

import FILEUPLOADED from "../../images/homepage/fileUploaded.png";
import FILENOTUPLOADED from "../../images/homepage/fileNotUploaded.png";

import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import { useMediaQuery } from '@mui/material';

import { getToken } from '../../authetication/loginState';

import Review5 from "../../images/homepage/review5.png";
import Review6 from "../../images/homepage/review6.png";
import Review7 from "../../images/homepage/review7.png";
import Review8 from "../../images/homepage/review8.png";
import Review9 from "../../images/homepage/review9.png";
import Feedback from "../../images/homepage/feedback.png";
import MockInterview from '../../images/homepage/mockInterview.png';

import CompanyCards from '../../components/CompanyCards';

SwiperCore.use([Pagination]);

function Homepage({ loginState }) {
    let navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const generateTokenEndpoint = process.env.REACT_APP_GENERATE_TOKEN_ENDPOINT;

    const [resumeUploaded, setResumeUploaded] = useState(false);
    const [file, setFile] = useState(null);

    const [tempToken, setTempToken] = useState("");
    const [fileName, setFileName] = useState("");
    const [loading, setLoading] = useState(false);

    const generateGuestToken = async () => {
        const response = await fetch(`${generateTokenEndpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    
        if (!response.ok) {
            throw new Error('Failed to generate guest token');
        }
    
        const data = await response.json();
        return data.token;
    };

    const handleFileUpload = (e) => {
        const uploadedFile = e.target.files[0];
        setFileName(uploadedFile.name);
        setFile(uploadedFile);
        if (uploadedFile) {
            setResumeUploaded(true);
        } else {
            setResumeUploaded(false);
        }
    };

    const applyInternship = async (e) => {
        setLoading(true);
        window.scrollTo(0, 0);
        e.preventDefault();
    
        const errorBanner = document.getElementById("errorBanner");
        if (errorBanner) {
            errorBanner.style.display = "none";
        }
    
        const applyInternshipData = {
            internshipId: '3a095a00-4d23-4ec1-9b3a-1be47a462a7a',
            userId: '22dce5bb-67d3-43c5-a2f8-a1624017c447', // Default user ID
            firstName: 'Default',
            lastName: 'commonApp',
            city: 'City',
            country: 'Country',
            phoneNumber: '6692042381',
            email: 'commonApp@gmail.com',
            resumeFileName: file.name,
        };
    
        const fd = new FormData();
        if (file) {
            fd.append("file", file);
        }
    
        let token = getToken();
    
        if (!token) {
            try {
                token = await generateGuestToken();
                setTempToken(token);
            } catch (error) {
                console.error("Error:", error);
                if (errorBanner) {
                    errorBanner.style.display = "block";
                }
                return;
            }
        } else {
            setTempToken(token);
        }
        
        const headers = {
            'Content-Type': 'application/json',
            ...(token && { Authorization: `Bearer ${token}` })
        };
    
        try {
            const applyResponse = await fetch(`${process.env.REACT_APP_APPLY_INTERNSHIP}`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(applyInternshipData)
            });
    
            if (!applyResponse.ok) {
                throw new Error('Failed to apply for internship');
            }
    
            const data = await applyResponse.json();
            const applicationId = data.studentAppliedIdReturn;
    
            const uploadHeaders = token ? { Authorization: `Bearer ${token}` } : {};
    
            const uploadResponse = await fetch(
                `${process.env.REACT_APP_ATTACH_RESUME}?student_applied_id=${applicationId}`,
                {
                    method: "POST",
                    headers: uploadHeaders,
                    body: fd,
                }
            );
    
            if (uploadResponse.status === 200) {
                alert("Cheetah Apply Successful!");
                window.location.reload();
            } else {
                alert("Cheetah Apply Unsuccessful");
                window.location.reload();
            }
        } catch (error) {
            console.error("Error:", error);
            if (errorBanner) {
                errorBanner.style.display = "block";
            }
        }
        setLoading(false);
    };       

    function previewRedirect() {
        if (loginState.loggedIn) {
            navigate('/Search')
        } else {
            navigate('/Login')
        }
    }

    const [openIndex, setOpenIndex] = useState(null);
    const faqRefs = useRef([]);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    useEffect(() => {
        const isMobile = window.innerWidth <= 600;
        faqRefs.current.forEach((ref, idx) => {
            if (ref) {
                if (openIndex === idx) {
                    const contentHeight = ref.scrollHeight;
                    ref.style.maxHeight = `${contentHeight}px`;
                    
                    if (isMobile) {
                        // For mobile: start at 170px, add extra padding based on content
                        const basePadding = 165;
                        const extraPadding = Math.min(contentHeight * 0.1, 100);
                        const totalPadding = basePadding + extraPadding;
                        ref.style.padding = `0 20px ${totalPadding}px`;
                    } else {
                        // For desktop: fixed 50px padding
                        ref.style.padding = '0 20px 70px';
                    }
                } else {
                    ref.style.maxHeight = '0';
                    ref.style.padding = '0 20px';
                }
            }
        });
    }, [openIndex]);
    
    const faqs = [
        {
            question: "Can Nora help me improve specific skills, like communication or technical knowledge?",
            answer: "Yes! Nora's got you covered. Whether it's technical know-how, communication skills, or overall interview performance, Nora gives you feedback on where you can improve and offers tips to help you level up."
        },
        {
            question: "Does Nora handle non-native speakers or users with accents?",
            answer: "Nora is designed to understand a variety of accents and speech patterns - you'll have an awesome experience regardless :)"
        },
        {
            question: "Can I track my progress and compare different interview sessions?",
            answer: "Yep, you sure can! Nora lets you look back at each mock interview, track your progress, and compare how you've done over time. It's a great way to see what's improving and what might still need some work."
        },
        {
            question: "Does Nora provide questions for both technical and non-technical roles?",
            answer: "Nora's got questions for all kinds of roles, whether you're going for something technical or more on the non-tech side. You can also toggle how technical or behavioral you want the interview to be. "
        },
        {
            question: "How much does Nora cost?",
            answer: "For just $14.99/month, you get unlimited interviews and unlimited feedback—honestly, it's a steal!"
        }
    ];

    const [isVisible, setIsVisible] = useState(false);
    const [scrollProgress, setScrollProgress] = useState(0);

    const resetProgress = () => {
        setScrollProgress(0);
    };

    useEffect(() => {
        const toggleVisibility = () => {
            const scrolled = document.documentElement.scrollTop;
            const maxHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrollPercentage = (scrolled / maxHeight) * 100;
        
            if (scrolled > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
            setScrollProgress(scrollPercentage);
        };
    
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        resetProgress();
    };

    return (
        <div className='homepage'>
            {loading && (
                <div className="loader3">
                    <div className="loader2"></div>
                </div>
            )}
            <div className="landing">
                <div className="left-container">
                    <h1>Meet AI Mock Interviewer, Nora.</h1>
                    <p style={{width: '85%'}}>Interview coming up? Sharpen your skills with AI-powered interview sessions and personalized feedback for any role.</p>

                    <div className="stats-inline">
                        <p><strong>1000+</strong> Offers Received</p>
                        <p><strong>1800+</strong> Users</p>
                    </div>
                </div>
            </div>

            {/* Logo slider */}
            <div className="logo-slider">
                <h2 className="companies-header">Companies Our Users Have Received Offers From</h2>
                <div className="logo-track">
                {/* Repeat the logos twice for a continuous effect */}
                {[...Array(2)].map(() => (
                    <>
                    <div className="logo-item">
                        <img src={AIRBNB} alt="Airbnb" />
                    </div>
                    <div className="logo-item">
                        <img src={AMAZON} alt="Amazon" />
                    </div>
                    <div className="logo-item">
                        <img src={TESLA} alt="Tesla" />
                    </div>
                    <div className="logo-item">
                        <img src={APPLE} alt="Apple" />
                    </div>
                    <div className="logo-item">
                        <img src={CHASE} alt="Chase" />
                    </div>
                    <div className="logo-item">
                        <img src={GOLDMANSACHS} alt="Goldman Sachs" />
                    </div>
                    <div className="logo-item">
                        <img src={CNBC} alt="CNBC" />
                    </div>
                    <div className="logo-item">
                        <img src={FIGMA} alt="Figma" />
                    </div>
                    <div className="logo-item">
                        <img src={NVIDIA} alt="NVIDIA" />
                    </div>
                    <div className="logo-item">
                        <img src={JNJ} alt="Johnsons & Johnson" />
                    </div>
                    <div className="logo-item">
                        <img src={LOCKHEEDMARTIN} alt="Lockheed Martin" />
                    </div>
                    <div className="logo-item">
                        <img src={GOOGLE} alt="Google" />
                    </div>
                    <div className="logo-item">
                        <img src={ROBINHOOD} alt="Robinhood" />
                    </div>
                    <div className="logo-item">
                        <img src={META} alt="Meta" />
                    </div>
                    <div className="logo-item">
                        <img src={TIKTOK} alt="TikTok" />
                    </div>
                    <div className="logo-item">
                        <img src={STRIPE} alt="Stripe" />
                    </div>
                    <div className="logo-item">
                        <img src={KPMG} alt="KPMG" />
                    </div>
                    <div className="logo-item">
                        <img src={SHADES} alt="Shades News" />
                    </div>
                    <div className="logo-item">
                        <img src={WALMART} alt="Walmart" />
                    </div>
                    </>
                ))}
                </div>
            </div>

            {!loginState.loggedIn && (
                <div className="button-container">
                <a href="/login">
                    <div className="button">
                        <p>{isMobile ? 'Practice with Nora' : 'Practice with Nora Mock Interviewer'}</p>
                    </div>
                </a>
            </div>
            )}

            <div className="how-it-works-1-partnership">
                <img src={MockInterview} alt="Mock interview picture" id="mock-interview" className='image1-styling'/>
            </div>

            <div className="outsourcing-solution">
                <h2 className="outsourcing-heading">Get Comprehensive Feedback</h2>
                <p className="outsourcing-description">
                    Receive customized feedback on each technical and behavioral question, as well as suggestions for improvement.  
                </p>
            </div>

            <div className="how-it-works-1-partnership">
                <img src={Feedback} alt="Feedback" id="feedback-picture" className='image2-styling'/>
            </div>

            {/* <div className="cheetah-mode-container">
                <div className="cheetah-mode-content">
                    <h2>Cheetah Mode</h2>
                    <p>The Internship Common App</p>
                    <p>Drop your resume here and automatically apply to <strong>every active position</strong> on the platform</p>
                    <button 
                        className="cheetah-apply-button" 
                        id="cheetah-apply-button" 
                        onClick={applyInternship} 
                        disabled={!resumeUploaded}
                        style={{
                            backgroundColor: resumeUploaded ? '#1DAF5C' : '#ccc',
                            cursor: resumeUploaded ? 'pointer' : 'not-allowed'
                        }}
                    >
                        Cheetah Apply
                    </button>
                </div>
                <div className="cheetah-mode-upload">
                    <div className="upload-box">
                        <input type="file" id="file-upload" accept=".pdf" onChange={handleFileUpload} />
                        <label htmlFor="file-upload">
                            <img src={!resumeUploaded ? FILENOTUPLOADED : FILEUPLOADED} alt="PDF Icon" />
                            {!resumeUploaded ? (
                                <>
                                    <p>Drop PDF here or Click to upload</p>
                                    <p>up to 10 MB</p>
                                </>
                            ) : (
                                <>
                                    <p>{fileName}</p>
                                </>
                            )}
                        </label>
                    </div>
                </div>
            </div> */}

            <div className="cta-container">
                <div className="cta-text">Hear from Applicants like you</div>
            </div>
            <div>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={isMobile ? 1 : 3}
                    modules={[Pagination]}
                    pagination={{ clickable: true }}
                    style={{ maxWidth: window.innerWidth * 0.9, margin: 'auto', paddingBottom: '70px', paddingTop: '20px' }}
                >
                    <SwiperSlide>
                        <div className="card">
                            <div className="card-top">
                                <img
                                    src={Review5}
                                    alt="Samantha Chou Photo"
                                    className="card-image-styling"
                                />
                            </div>
                            <div className="card-content">
                                <h3>Samantha Chou</h3>
                                <p className="title">Cognitive Science, Senior</p>
                                <p className="quote">
                                    “Nora is a helpful and easy-to-use mock interview tool. 
                                    Love the interactive nature and the question-by-question feedback and analysis! 
                                    Very useful in the job searching process!”
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card">
                            <div className="card-top">
                                <img
                                    src={Review6}
                                    alt="Carissa Wu Photo"
                                    className="card-image-styling"
                                />
                            </div>
                            <div className="card-content">
                                <h3>Carissa Wu</h3>
                                <p className="title">Computer Engineering, Senior</p>
                                <p className="quote">
                                “Great tool that simulates real life interviews. 
                                I used it to practice for a web developer position, 
                                and the questions Nora asked were what my interviewer asked me.”
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card">
                            <div className="card-top">
                                <img
                                    src={Review7}
                                    alt="Dev Popat Photo"
                                    className="card-image-styling"
                                />
                            </div>
                            <div className="card-content">
                                <h3>Dev Popat</h3>
                                <p className="title">SWE at Roku</p>
                                <p className="quote">
                                “I completed 3 mock interviews before my actual interview at Roku, 
                                and 4 out of the 7 questions were exactly the same! 
                                This is a must-use tool for anyone preparing for interviews!”
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card">
                            <div className="card-top">
                                <img
                                    src={Review8}
                                    alt="Julia Son Photo"
                                    className="card-image-styling"
                                />
                            </div>
                            <div className="card-content">
                                <h3>Julia Son</h3>
                                <p className="title">Cognitive Science, Junior</p>
                                <p className="quote">
                                “Easily the best way to prepare for an interview. 
                                With constructive, personalized feedback, I'd recommend Nora to anybody looking to 
                                prepare for any upcoming interviews. Such a great resource!”
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card">
                            <div className="card-top">
                                <img
                                    src={Review9}
                                    alt="Sydney Nguyen Photo"
                                    className="card-image-styling"
                                />
                            </div>
                            <div className="card-content">
                                <h3>Sydney Nguyen</h3>
                                <p className="title">Political Science, Senior</p>
                                <p className="quote">
                                “Nora is super helpful and gives realistic questions. 
                                I love the specific focus on information provided from your resume - it's especially helpful for creative positions 
                                that require a wide variety of skills. 100% recommend!”
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>

            </div>

            {/* FAQ Section */}
            <div className="faq-section">
            <h2 style={{ fontWeight: 'bold', fontSize: '35px', marginBottom: '30px' }}>Have questions?</h2>
            {faqs.map((faq, index) => (
                <div className="faq-item" key={index}>
                    <button 
                        className="faq-question"
                        onClick={() => toggleFAQ(index)}
                    >
                        {faq.question}
                        <span className="arrow">{openIndex === index ? '▼' : '▲'}</span>
                    </button>
                    <div 
                        className="faq-answer"
                        ref={el => faqRefs.current[index] = el}
                    >
                        {faq.answer}
                    </div>
                </div>
            ))}
            </div>

            {/* Company Mock Interview Section */}
            <div>
                <h2 className="company-mock-section-section">Jump start your interview practice now.</h2>
                <CompanyCards />
            </div>

            <div className="outsourcing-solution">
                <h2 className="outsourcing-heading">Watch Nora in Real Time</h2>
                <p className="outsourcing-description" style={{ marginBottom: '50px'}}>
                    Watch how Nora works in real life, and can offer valuable interview practice.
                </p>
                <iframe 
                    width={isMobile ? "350" : "800"}
                    height={isMobile ? "210" : "515"}
                    style={isMobile ? { marginLeft: '13px'} : { marginLeft: '0px'}}
                    src="https://www.youtube.com/embed/pWUZ2nNe5wg?si=ikAIZTgeColgVlPS" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen>
                </iframe>
            </div>

            <div className={isMobile ? "practiceNoraCard-mobile": "practiceNoraCard"}>
                <div style={{ textAlign: "center" }}>
                    <div className={isMobile ? "practiceNoraCardHead-mobile": "practiceNoraCardHead"}>Ready to begin mastering interviews with Nora?</div>
                    <button className="practiceNoraButton" onClick={() => navigate('/login')}>Practice Now</button>
                </div>
            </div>

            <Footer />
            {isVisible && (
                <button
                    onClick={scrollToTop}
                    className="scroll-to-top-button"
                >
                    <div className="button-content">
                        <svg
                            width="52"
                            height="52"
                            viewBox="0 0 52 52"
                        >
                            <circle
                                cx="26"
                                cy="26"
                                r="25"
                                fill="none"
                                stroke="#ffffff"
                                strokeWidth="2"
                                strokeDasharray="157"
                                strokeDashoffset={157 - (157 * scrollProgress) / 100}
                            />
                        </svg>
                        <span className="arrow">↑</span>
                    </div>
                </button>
            )}
        </div>
    )
}

export default Homepage;