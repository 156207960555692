import React, { useState } from 'react';
import Resume from '../../images/resume.png';

const SectionTitle = ({ text, subtitle }) => (
    <div className="section-title-container">
        <h1 className="section-title">{text}</h1>
        <p className="section-subtitle">{subtitle}</p>
    </div>
);

const NoraUploader = ({
  position,
  setPosition,
  companyName,
  setCompanyName,
  jobDescription,
  setJobDescription,
  technicalLevel,
  handleFileChange,
  triggerFileInput,
  handleTechnicalLevelChange,
  fileName,
  handleGetStarted,
  isFormComplete,
  isModal,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileChange({ target: { files: [file] } });
    }
  };

  return (
    <>
    <SectionTitle
        text="AI Mock Interviewer"
        subtitle="Meet Nora, our AI Mock Interviewer! Input your resume, position, and provide a job description. Nora will simulate a realistic interview to streamline your prep :)"
    />

      <div className="form-container">
        <div className="form-section left-section">
          <div className={`form-group file-upload ${isDragging ? 'dragging' : ''}`}
            onClick={triggerFileInput}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <input
              type="file"
              id="fileInput"
              className="form-control"
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx"
              style={{ display: 'none' }}
            />
            <div className="file-upload-content">
              <img src={Resume} alt="PDF Icon" className="pdf-icon" />
              {fileName ? (
                <p className="file-name">{fileName}</p>
              ) : (
                <>
                  <p>{isDragging ? 'Drop your resume here' : 'Click to upload your resume here *'}</p>
                  <p className="file-size">up to 10 MB - pdf or .docx</p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="right-sections">
            <div className="form-section">
                <div className="form-group">
                    <label>
                        Position <span className="required-asterisk">*</span>
                    </label>
                    <input
                        className="form-control"
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                        placeholder="ex. UX Design Intern" />
                </div>
            </div>
            <div className="form-section">
                <div className="form-group">
                    <label>
                        Company Name <span className="required-asterisk">*</span>
                    </label>
                    <input
                        className="form-control"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        placeholder="ex. Google" />
                </div>
            </div>

            <div className="form-section">
                <div className="form-group">
                    <label>
                        Technical Level <span className="required-asterisk">*</span>
                    </label>
                    <p>
                        How technical would you like this interview to be on a scale from 0
                        (Behavioral questions) to 10 (Technical questions)
                    </p>
                    <input
                        type="range"
                        className={isModal? "custom-slider": "custom-slider margin-left"}
                        value={technicalLevel}
                        min="0"
                        max="10"
                        step="5"
                        onChange={handleTechnicalLevelChange} />
                </div>
                <div className="slider-labels">
                    <div className="slider-text"><div><b>0</b></div><div>Not Technical At All</div></div>
                    <div className="slider-text"><b>5</b></div>
                    <div className="slider-text"><div><b>10</b></div><div>Completely Technical</div></div>
                </div>
                
            </div>

            <div className="form-section">
                <div className="form-group">
                    <label>Job Description</label>
                    <textarea
                        className="form-control"
                        value={jobDescription}
                        onChange={(e) => setJobDescription(e.target.value)}
                        placeholder="Enter the position's job description - gives our AI the ultimate context!" />
                </div>
            </div>
        </div>
    </div>
    <div className="get-started-button-container">
        <button
            className={`btn ${isFormComplete ? 'btn-primary' : 'btn-disabled'}`}
            onClick={handleGetStarted}
            disabled={!isFormComplete}
        >
            Get Started
        </button>
    </div>
    </>
)};

export default NoraUploader;